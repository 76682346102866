import moment from 'moment';
import 'magnific-popup';
import 'owl.carousel';
import 'jquery-countto';
jQuery( document ).ready( function ( $ ) {
	// Nav
	$( '#header .hamburger' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( this ).toggleClass( 'is-active' );
		$( '#nav-collapse' ).slideToggle();
	} );
	$( '#header nav>ul>li.local>a' ).click( function ( e ) {
		e.preventDefault();
		const href = $( this ).attr( 'href' );
		$( 'html, body' ).animate( {
			scrollTop: $( href ).offset().top
		}, 1500 );
	} );
	// Social Impact
	$( '.count' ).countTo( {
		speed: 2500,
		refreshInterval: 50,
		formatter: function ( value, options ) {
			value = value.toFixed( options.decimals );
			value = value.replace( /\B(?=(\d{3})+(?!\d))/g, ',' );
			return value;
		},
	} );
	// Videos
	$( '#videos .owl-carousel' ).owlCarousel( {
		loop: true,
		video: true,
		items: 1,
		dots: false,
		nav: true,
		navText: [
			'<i class="fas fa-angle-left"></i><span class="sr-only">Prev</span>',
			'<i class="fas fa-angle-right"></i><span class="sr-only">Next</span>'
		]
	} );
	// Bio
	$( '#bio .btn' ).magnificPopup( {
		type: 'inline',
		midClick: true
	} );
	// Tour
	if ( $( '#events' ).length ) {
		$.ajax( {
			url: 'https://rest.bandsintown.com/artists/Kane%20Brown/events?app_id=45PRESS_KANE_BROWN_B2B',
			method: 'GET',
			dataType: 'json',
			error: () => {
			},
			success: data => {
				const events = $( '#events' );
				let html = '';
				if ( data.length ) {
					console.log( data );
					for ( let event of data ) {
						const region = event.venue.region !== null && event.venue.region !== '' ? event.venue.region : event.venue.country;
						const location = event.venue.city + ', ' + region;
						html += '<div class="event" data-bit-id="' + event.id + '">';
						html += '<div class="event-date">' + moment( event.datetime ).format( 'MMMM DD, Y' ) + '<div class="event-venue">' + event.venue.name + '</div>' + '</div>';
						html += '<div class="event-venue-location">';
						html += '<div class="event-location">' + location + '</div>';
						html += '</div>';
						html += '<div class="event-links">';
						for ( let offer of event.offers ) {
							if ( offer.type === 'Tickets' ) {
								html += '<a href="' + offer.url + '" target="_blank" class="btn btn-blue">' + offer.type + '</a>';
							}
						} 
						html += '</div>';
						html += '</div>';
					}
					events.html( html );
				} else {
					events.html( 'No upcoming events.' );
				}
			}
		} );
	}
	// Tabs
	$( '.download-tabs>li>a' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( '.download-tabs>li>a' ).removeClass( 'active' );
		$( this ).addClass( 'active' );
		const target = $( this ).attr( 'data-target' );
		$( '.download-tabs-panels>div' ).hide();
		$( '.download-tabs-panels>div' + target ).show();
	} );
} );